@media print {
  @page {
    size: auto; /* auto is the initial value */

    /* this affects the margin in the printer settings */
    margin: 5mm 5mm 5mm 5mm;
  }

  body {
    /* this affects the margin on the content before sending to printer */
    margin: 0px;
  }
  .printableFont {
    color: #000 !important;
    font-size: 8px !important;
  }

  .printableColor {
    color: #000 !important;
  }

  .hideOnPrint {
    display: none;
  }

  .page-break-here {
    page-break-after: always !important;
  }
  .watermark img {
    display: block !important;
    position: fixed;
    top: 10%;
    left: 15%;
    opacity: 0.1;
    width: 550px;
  }
}

.watermark img {
  display: none;
}

/* required fields custom css */
.MuiFormLabel-asterisk {
  font-size: 20px !important;
  line-height: 0% !important;
}
